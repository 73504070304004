<template>
  <v-dialog v-model="open" max-width="500px">
    <CustomAddEditComponent
      v-if="open"
      class="team-component"
      :pCardTitle="editedTeam && editedTeam.id ? 'Edit Team' : 'New Team'"
      :pEditedItem="editedTeam"
      :pEditedItemType="TEAM_ADD_EDIT_ITEM"
      :pItemParams="teamParamsForEdit"
      :pIsDialog="true"
      :pLoading="loading"
      :pDisabledParams="disabledParams"
      @onCancelled="open = false"
      :pBeforeItemCreatedOrUpdated="openConfirmationManagerDialogOrSaveTeam"
      @onItemCreatedOrUpdated="saveTeamAndCloseDialog($event)"
    />
    <ConfirmationDialog ref="confirmManagerDialog"/>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import {
  dismissAction, logAndExtractMessage, TEAM_ADD_EDIT_ITEM, TEAM_PARAMS,
} from "../../utils/utils";

export default {
  name: "TeamDialog",

  components: {
    CustomAddEditComponent: () => import("../custom-components/AddEditComponent.vue"),
    ConfirmationDialog: () => import("./ConfirmationDialog.vue"),
  },

  data: () => ({
    TEAM_ADD_EDIT_ITEM,
    TEAM_PARAMS,

    editedTeam: {},
    selectedHost: undefined,
    open: false,
    loading: false,
    disabledParams: [],
  }),

  computed: {
    teamParamsForEdit() {
      return TEAM_PARAMS.filter((it) => it.value !== "managerEmail");
    },
  },

  methods: {
    openDialog(team, disabledParams = []) {
      this.selectedHost = team.host;
      this.disabledParams = disabledParams;
      this.editedTeam = team;
      this.open = true;
    },

    openConfirmationManagerDialogOrSaveTeam(team) {
      if (!team.managerEmail) {
        return true;
      }
      this.$refs.confirmManagerDialog.openDialog({
        header: "Confirm manager",
        text: `
          The manager is responsible to load and maintain the application for the Team.
          Once you nominate someone to be the Team Manager they will have 5 days to accept
          or decline the nomination before it expires.
          Once the manager ${team.managerEmail} accepts the role they can not be removed without recreating the team.
        `,
        submitText: "Confirm",
        submitColor: "green",
        onSubmit: () => { this.saveTeamAndCloseDialog(team); },
      });
      return false;
    },

    async saveTeamAndCloseDialog(team) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "teamsModule/addOrSaveTeam",
          this.editedTeam.id
            ? {
              id: this.editedTeam.id,
              ...team,
            }
            : {
              hostId: this.selectedHost.id,
              ...team,
            },
        );
        this.open = false;
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.loading = false;
    },
  },
};
</script>
